import { pushEvent } from "@wbly/common";
import type { W, DataLayer } from "@wbly/common";

export const trackFeatureTilesClick = (
  featureTilesRowId: string,
  featureTilesIndex: number
) => {
  pushEvent(
    (window as W)?.dataLayer as DataLayer[],
    "",
    `${featureTilesRowId}`,
    `Card position: ${featureTilesIndex}`
  );
};
